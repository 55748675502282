
@font-face {
    font-family: 'Publica Play Regular','Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;;
    font-style: normal;
    font-weight: normal;
    src:  url('https://e-menupull.b-cdn.net/PublicaPlay-Regular.woff') format('woff');
    }
    
    .wrapper {
    /*   max-width:1024px; */
      font-family:'Publica Play Regular','Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;;
      width:100vw;;
      height:100vh;
      display:flex;
      flex-direction:column;
      align-items:center;
      padding:0.8em;
    
     background-repeat: no-repeat;
      background-size: cover;
      background-image:url('https://e-menupull.b-cdn.net/e-menu-background_gradient.png')
    /*   background-color:blue; */
    }
    
    
    .btnStyle
    {
      font-family:'Publica Play Regular','Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;;
      margin-top:0.5em;
      color:white;
      font-size:1em;
      width:90%;
      height:3em;
      max-width:20em;
      background : #004FC9;
      background : rgba(0, 79, 201, 1);
      border-style : Solid;
      border-color : #FFFFFF;
      border-color : rgba(255, 255, 255, 1);
      border-width : 1pt;
      opacity : 0.65;
      border-radius : 1vh;
      -moz-border-radius : 1vh;
      -webkit-border-radius : 1vh;
      filter: alpha(opacity=65) progid:DXImageTransform.Microsoft.Alpha(opacity=65) ;
    }
    .demoImage {
     min-height: 30vh;
    height:auto;
    height:35vh;
    }
    .header {
      paddibng:0.3em;
      width:100%;
      height:2.5em;
      display:flex;
      justify-content:space-between;
    /*   background-color:red; */
    }
    